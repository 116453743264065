
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { EventInterface } from '../types'
import { toLocalDate } from '@/utils/dateFormatter'

import BaseButton from '@/components/base/BaseButton.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import Badge from '@/components/Badge.vue'

@Component({
  name: 'EventList',
  components: {
    BaseButton,
    BaseIcon,
    Badge,
  },
})
export default class extends Vue {
  @Prop({ required: true })
  events!: EventInterface[]

  @Watch('events')
  onEventsChanged(val: EventInterface[]): void {
    if (this.selected === 0 && val.length) {
      this.selected = val[0].id
      this.$emit('select', val[0])
    } else if (val.length === 0) {
      this.selected = 0
    }
  }

  selected = 0
  toLocalDate = toLocalDate

  select(event: EventInterface): void {
    this.selected = event.id
    this.$emit('select', event)
  }
}
